/*
 * Global jQuery for footer
 */
/* global Cookies */

// Useful elements
var biro_body;

// Viewport vars
var biro_vw;
var biro_vw_xs;
var biro_vw_sm;
var biro_vw_md;
var biro_vw_lg;
var biro_vw_mobile;

jQuery(function($) {
	var preload;
	biro_body = $('body');

	// Preload images
	preload = new Image();
	preload.src = biro_main.ajax_spinner_url;

	// Hide stuff that needs to begin hidden with JS
	// (but doesn't use hidden-js class cos it can't be overridden with jQuery.show() etc.)
	$('.hidden-by-js').hide();


  // Mobile menu

    $('#mobile-menu-toggle').on('click', function(){

    	$('#mobile-menu-overlay').fadeToggle(200);
    	$('#mobile-menu-nav').slideToggle(200);

    });

    $('#mobile-menu-primary > li > a').on('click', function(e){

    	e.preventDefault();

    	$(this).siblings('.sub-menu').slideToggle(200);

    });


  // Share popups

    $('.social-share-button').on('click',function(){

	    // Popup window settings
	    var url = $(this).attr('href');

	    // Pop the window
	    var newwindow = window.open(url,'socialpop','height=285,width=600');
	    if (window.focus) { newwindow.focus(); }
	    return false;

    });


	/**
	 * Filters
	 */
	$('.fitvids,.content-styles').fitVids();

	/**
	 * Filters
	 */
	$('#publications-filter-dropdown').on( 'change', function(){

		$('#publications-filter-form').submit();

	} );

	/**
	 * Privacy notice
	 */

    if(
      Cookies.get('privacy-cookies') === undefined &&
      navigator.doNotTrack !== '1' &&
      window.doNotTrack !== '1'
    ) {

      document.getElementById('privacy-notice').style.display = 'block';

    }

    if(
      navigator.doNotTrack === '1' ||
      window.doNotTrack === '1'
    ) {

      Cookies.set('privacy-cookies', 'reject', { expires: 1 });

    }

    $( '#privacy-cookies-accept' ).on( 'click', function() {

      Cookies.set('privacy-cookies', 'accept', { expires: 365 });

      $('#privacy-notice').slideUp(200);

    });

    $( '#privacy-cookies-reject' ).on( 'click', function() {

      Cookies.set('privacy-cookies', 'reject', { expires: 365 });

      $('#privacy-notice').slideUp(200);

    });

	/**
	 * Privacy notice
	 */

	$('.accordion-header').on('click', function(){

		$(this).next('.accordion-content').slideToggle(200);

		$(this).children('.accordion-plus, .accordion-minus').fadeToggle(0);

	});

	/**
	 * Viewport infos
	 */
	// Init viewport infos
	biro_viewport_infos();

	// Trigger on window resize and refresh viewport infos
	$(window).resize(function() {
		waitForFinalEvent(
			function() {
				biro_viewport_infos();
			},
			200,
			'viewport-infos'
		);
	});

	/**
	 * Navigation
	 */

	// Search
	$('#menu-search-toggle').on('click', function(){

		if ( ! $('#menu-search-form').hasClass('is-active') ) {

			var search_width = $('#header-menu-main').innerWidth();

			$('#menu-search-form').animate(
				{ width: search_width },
				200,
				function() {
					$('#menu-search-form').addClass('is-active');
					$('#menu-search-text').focus();
				}
			);

		} else {

			$('#menu-search-form').animate(
				{ width: '0' },
				200,
				function() {
					$('#menu-search-text').blur();
					$('#menu-search-form').removeClass('is-active');
				}
			);

		}

	});

	// Manage both mouse and keyboard behaviours
	$('.menu-dynamic')
		.on(
			'mouseenter focus',
			'.menu-level-0.menu-item-has-children > .menu-item-link',
			function(e) {
				if (!biro_vw_mobile) {
					var el = $(this);
					el.toggleClass('has-focus');
					//console.log( 'focus top level link: ' + el.text() );
					// Show sub-menu
					el.parents('.menu-item').attr('aria-expanded', 'true');
				}
			}
		)
		.on(
			'mouseleave blur',
			'.menu-level-0.menu-item-has-children > .menu-item-link',
			function(e) {
				if (!biro_vw_mobile) {
					var el = $(this);
					el.toggleClass('has-focus');
					//console.log( 'blur top level link: ' + el.text() );
					// Only hide sub-menu after a short delay, so links get a chance to catch focus from tabbing
					setTimeout(function() {
						var smw = el.siblings('.sub-menu-wrapper');
						if (smw.attr('data-has-focus') !== 'true') {
							el.parents('.menu-item').attr('aria-expanded', 'false');
						}
					}, 100);
				}
			}
		)
		.on('mouseenter focusin', '.sub-menu-wrapper', function(e) {
			if (!biro_vw_mobile) {
				var el = $(this);
				//console.log( 'focus sub-menu-wrapper' );
				el.attr('data-has-focus', 'true');
			}
		})
		.on('mouseleave blur', '.sub-menu-wrapper', function(e) {
			if (!biro_vw_mobile) {
				var el = $(this);
				setTimeout(function() {
					// Check if anything else has picked up focus (i.e. next link in sub-menu)
					if (el.find(':focus').length === 0) {
						//console.log( 'blur sub-menu link: ' + el.text() );
						el.attr('data-has-focus', 'false');
						// Hide sub-menu on the way out if parent link doesn't have focus
						if (el.siblings('.menu-item-link.has-focus').length === 0) {
							el.parents('.menu-level-0').attr('aria-expanded', 'false');
						}
					}
				}, 100);
			}
		})
		.on('click', '.sub-menu-control', function(e) {
			// Handle sub-menu control for mobile
			if (biro_vw_mobile) {
				var el = $(this);
				var p = el.parents('[aria-expanded]');
				el.toggleClass('sub-menu-control-open');
				p.attr(
					'aria-expanded',
					p.attr('aria-expanded') == 'true' ? 'false' : 'true'
				);
			}
		});

	/**
	 * Bootstrap mods
	 */

	// Focus first input when collapsed element opens
	biro_body.on('shown.bs.collapse', '.biro-collapse-focus', function() {
		$(this)
			.find('input')
			.first()
			.focus();
	});

	// FUNCTION TO ALLOW THROTTLED FUNCTION EXECUTION ON WINDOW RESIZE

	var waitForFinalEvent = (function() {
		var timers = {};

		return function(callback, ms, uniqueId) {
			if (!uniqueId) {
				uniqueId = "Don't call this twice without a uniqueId";
			}

			if (timers[uniqueId]) {
				clearTimeout(timers[uniqueId]);
			}

			timers[uniqueId] = setTimeout(callback, ms);
		};
	})();

	// CLOSE NOTICES

	$('#privacy-notice-close').on('click', function(e) {
		e.preventDefault();

		$('#privacy-notice').slideUp(200);
	});

	// SOCIAL SHARE POPUPS

	$('#social-share .social-share-button').on('click', function(e) {
		// Popup a window if it's not an email link
		if (!$(this).hasClass('social-share-email')) {
			// Prevent default action but allow propagation
			e.preventDefault();

			// Pop the window
			var socialShareWindow = window.open(
				$(this).attr('href'),
				'socialWindowTarget',
				'height=285,width=600'
			);

			// Focus the window
			if (window.focus) {
				socialShareWindow.focus();
			}
		}
	});

	// IMAGE ENHANCEMENT

	// Function to enhance images
	function images_enhance() {
		// Loop through all images with appropriate class
		$('.image-enhance').each(function() {
			// Set the jQuery object as a variable
			var $image_to_enhance = $(this);

			// Get the window width
			var window_width = $(window).innerWidth();

			// Only try this on sm screens and above
			if (window_width > 767) {
				// Large
				if (window_width > 1199) {
					var enhance_lg = $image_to_enhance.attr('data-enhance-lg');
					$image_to_enhance.css('background-image', enhance_lg);
				} else if (window_width > 991) {
					// Medium
					var enhance_md = $image_to_enhance.attr('data-enhance-md');
					$image_to_enhance.css('background-image', enhance_md);
				} else {
					// Small
					var enhance_sm = $image_to_enhance.attr('data-enhance-sm');
					$image_to_enhance.css('background-image', enhance_sm);
				}
			}
		});
	}

	// Enhance images on window resize
	$(window).resize(function() {
		waitForFinalEvent(
			function() {
				images_enhance();
			},
			200,
			'images-enhance'
		);
	});

	// Enhance images on load
	images_enhance();
});

/**
 * Viewport infos
 */
function biro_viewport_infos() {
	jQuery(function($) {
		biro_vw = window.innerWidth;
		biro_vw_xs = biro_vw < 768;
		biro_vw_sm = biro_vw >= 768 && biro_vw < 992;
		biro_vw_md = biro_vw >= 992 && biro_vw < 1200;
		biro_vw_lg = biro_vw > 1200;
		biro_vw_mobile = biro_vw_xs;
	});
}
